import { Controller } from 'stimulus';

export default class UsersTable extends Controller {
  toggleModal = e => {
    const { id } = e.target.dataset;

    if (e.target.checked) {
      $(`#user-confirmed-confirmation-modal-${id}`).modal('show');
    } else {
      $(`#user-unconfirmed-confirmation-modal-${id}`).modal('show');
    }
  };
}
